<template>
    <div id="disconnection">
        <div class="parking-qrcode">
            <p class="parking-qrcode-p">请扫码开闸</p>
            <div id="qrcode" ref="qrcode"></div>
        </div>
        <div class="parking-info">
            <div class="line">
                <div class="line-label">车牌号</div>
                <div class="line-key">{{licencePlate}}</div>
            </div>
            <div class="line">
                <div class="line-label">应收金额</div>
                <div class="line-key">{{amount}}</div>
            </div>
            <div class="line">
                <div class="line-label">开始停车</div>
                <div class="line-key">{{timestampToTime(inTime)}}</div>
            </div>
            <div class="line">
                <div class="line-label">停车时长</div>
                <div class="line-key">{{getDuration(parkTime)}}</div>
            </div>
            <div class="line">
                <div class="line-label">距离下一个计费周期</div>
                <div class="line-key">
                    <div class="line-key-time">{{time}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Swipe, SwipeItem } from 'vant';
    import QRCode from 'qrcodejs2'
    export default {
        name: "home",
        props:{
            inTime:{
                type:String,
                default:''
            },
            amount:{
                type:[Number,String],
                default:0
            },
            licencePlate:{
                type:String,
                default:''
            },
            codeUrl:{
                type:String,
                default:''
            },
            parkTime:{
                type:String,
                default:''
            },
            leftMinutes:{
                type:String,
                default:0
            }

        },
        data(){
            return{
                qrCode: 'https://payapp.weixin.qq.com',
                time:''
            }
        },
        methods:{
            getQrCode() {
                document.getElementById('qrcode').innerHTML = ''
                let num = window.innerWidth*(300/375);
                console.log(window.innerWidth)
                console.log(window.innerWidth)
                console.log(num,'二维码宽度')
                let qrcode = new QRCode('qrcode', {
                    width: num,
                    height: num,
                    text: this.codeUrl, // 二维码地址
                    colorDark: "#000",
                    colorLight: "#fff",
                    correctLevel: QRCode.CorrectLevel.H // 容错等级，H是heigh，最高，所以二维码看起来很密
                })
            },
            process(){
               let s = parseInt(this.leftMinutes)-1;
               var timer = setInterval(() => {
                console.log(s);
                   if(s==0){
                       clearInterval(timer)
                   }
                    s--;
                    this.getTime(s);
                    console.log(s)
                }, 1000);
                
            },
            getTime(s){
                let h = parseInt(s/3600)>0?parseInt(s/3600)>9?parseInt(s/3600):'0'+parseInt(s/3600)+':':'00';
                let m = parseInt(s%3600/60)>0?parseInt(s%3600/60)>9?':'+parseInt(s%3600/60):':0'+parseInt(s%3600/60):':00';
                let ss =parseInt(s%60)>0?parseInt(s%60)>9?':'+parseInt(s%60):':0'+parseInt(s%60):':00';
                this.time =h+m+ss;
                console.log(h,m,ss)
            },
            getDuration(value){
                let theTime = parseInt(value)
                let theTime1 = 0 // 小时
                let theTime2 = 0 // 天
                if(!theTime){
                    return 0+'分钟'
                }
                if (theTime >=60) {
                    theTime1 = parseInt(theTime / 60)
                    theTime = parseInt(theTime % 60)
                    if (theTime1 > 24) {
                    // 大于24小时
                    theTime2 = parseInt(theTime1 / 24)
                    theTime1 = parseInt(theTime1 % 24)
                    }
                }
                let result = []
                if (theTime2 > 0) {
                    result.push({
                    'unit': '天',
                    'time': parseInt(theTime2)
                    })
                }
                if (theTime1 > 0) {
                    result.push({
                    'unit': '小时',
                    'time': parseInt(theTime1)
                    })
                }
                if (theTime > 0) {
                    result.push({
                    'unit': '分钟',
                    'time': parseInt(theTime)
                    })
                }
                console.log(result)
                return result[0].time+result[0].unit
            },
            timestampToTime(time){
                if(!time){
                    return ''
                }
                var date = new Date(time);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                let h = date.getHours();
                h = h < 10 ? "0" + h : h;
                let m = date.getMinutes();
                m = m < 10 ? "0" + m : m;
                let s = date.getSeconds();
                s = s < 10 ? "0" + s : s;
                return y + "-" + MM + "-" + d + " " + h+ ":" + m;
            }
        },
        mounted() {
            let than = this;
            this.getTime(parseInt(this.leftMinutes));
            than.process();
            setTimeout(() => {
                than.getQrCode();
            }, 500);
        },
        components:{
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem
        }
    }
</script>

<style lang="scss" scoped>
    #disconnection{
        width: 100%;
        height: auto;
        overflow: hidden;
    }
    .parking-qrcode{
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .parking-qrcode #qrcode{
            width: 100%;
            text-align: center;
    }
    .parking-qrcode-p{
        font-size: 32px;
        font-weight: bolder;
        padding: 0px 0px;
        padding-bottom:20px;
        margin: 0;
    }
   .parking-info{
    width: 90%;
    height: auto;
    margin: 0px auto;
    margin-top: 20px;
    border: 1px dashed #aaa ;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: rgb(242, 242, 242);

   }
   .parking-info .line{
        width: 100%;
        line-height: 24px;
        display: flex;
        padding: 0px 10px;
        box-sizing: border-box;
        align-items: center;
        font-size: 12px;
    }
    .parking-info .line .line-label{
            width: 30%;
            color: rgb(157, 157, 157);
            max-width: 100px;
     }
     .parking-info .line .line-key{
            padding-left: 16px;
            width: 60%;
            color: rgb(63, 63, 63);
    }
    .parking-info .line .line-key-time{
            width: 100px;
            height: 30px;
            background-color: rgb(252, 123, 26);
            line-height: 30px;
            color: #fff;
            text-align: center;
            border-radius: 6px;
    }
</style>
