<template>
    <div>
        <div v-if="netWorkStatus===0">
            <div class="success">
            <div class="success-img">
                <img src="../assets/imgs/success.png" alt="">
            </div>
            <div class="text" @click="openVconsole">支付成功</div>
            <div class="button">
                <button @click="btnFun" v-if="enableInvoice===1">开发票</button>
            </div>
            </div>
            <div class="img-box">
                <a href="">
                    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#07c160">
                        <van-swipe-item v-for="(item, index) in ad" :key="index">
                            <img :src="item['pic']" @click="imgClickFun(item['clickUrl'])">
                        </van-swipe-item>
                        <van-swipe-item>
                            <div id="abBox"></div>
                        </van-swipe-item>
                    </van-swipe>
                </a>
                <!-- <a href="" v-if="ad.length===1">
                    <img :src="ad[0]['pic']" @click="imgClickFun(ad[0]['clickUrl'])">
                </a> -->
            </div>
        </div>
       <disconnection-home-vue v-else  :inTime="inTime" :amount="amount" :licencePlate="licencePlate" :codeUrl="codeUrl" :parkTime="parkTime" :leftMinutes="leftMinutes"></disconnection-home-vue>
    </div>

</template>

<script>
    import axios from "axios"
    import { Swipe, SwipeItem } from 'vant';
    import disconnection_homeVue from "../compoent/disconnection_home.vue";
    import VConsole from 'vconsole'
    export default {
        name: "home",
        data(){
            return{
                sub_mch_id: "",
                out_trade_no: "",
                check_code: "",
                ad: [],
                orderId: "",
                parkId: "",
                transactionId: "",
                licencePlate: "",
                amount: "",
                parkName: "",
                enableInvoice: "",
                orderId:'',
                transactionTime:'',
                amount:'',
                codeUrl:'',
                netWorkStatus:0,
                inTime:'',
                parkTime:'',
                time:200,
                showAb:false
            }
        },
        methods:{
            getQueryString(name){
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if (pair[0] == name) {
                        return pair[1];
                    }
                }
                return null;
            },
            imgClickFun(url){
                var Data = {
                    action: 'jumpOut',
                    jumpOutUrl: url
                };
                var postData = JSON.stringify(Data);
                parent.postMessage(postData, 'https://payapp.weixin.qq.com')
            },
            btnFun(){
                var Data = {
                        action: 'jumpOut',
                        jumpOutUrl: `http://wx.hntpsjwy.com/WALLET/invoice_full_electronic_invoice/${this.parkId}/plateNumber/LicencePlate/${this.licencePlate}`
                    };
                    console.log(Data)
                    var postData = JSON.stringify(Data);
                    parent.postMessage(postData, 'https://payapp.weixin.qq.com');
                
                // axios.get(`/v1/api/fapiao/e_invoice?oId=${this.orderId}&lp=${this.licencePlate}&pId=${this.parkId}&at=${this.amount*100}&withoutSign=${1}&isOnlineInvoice=1&transactionId=${this.transactionId}`).then(res=>{
                //     var Data = {
                //         action: 'jumpOut',
                //         jumpOutUrl: res.data.data.url
                //     };
                //     var postData = JSON.stringify(Data);
                //     parent.postMessage(postData, 'https://payapp.weixin.qq.com');
                // })
            },
            getParkId(str) {
                if(!str)return 1
                let parkId
                let arr = (str.split('_'))
                if(arr.length>0){
                    parkId = arr[1].substring(0,5)
                }
                return parkId.replace(/^0+/, '')
            },
            openVconsole() {
                new VConsole()
                this.$nextTick(()=>{
                    console.log(window.location,'-----window.location');
                    console.log(window.location.search,'-----window.location.search');
                    console.log(this.getParkId(this.out_trade_no),'-------getParkId');
                    
                })
            }
        },
        async created() {
            const customPageHeight = document.body.scrollHeight;
            console.log(customPageHeight);
            const height = customPageHeight * (640 / Math.round(document.documentElement.getBoundingClientRect().width));
            let initData = {
                action: 'onIframeReady',
                displayStyle: 'SHOW_CUSTOM_PAGE',
                height: "700"
            };
            this.out_trade_no = this.getQueryString("out_trade_no");
            console.log(this.out_trade_no,'this.out_trade_no')
            let res = await axios.get(`/v1/api/parkOffline/network_status?transactionId=${this.out_trade_no}`);
            console.log(res,'res')
            if(res.data.success){
                console.log(res,'接收到的信息');
                let data = res.data.data;
                this.netWorkStatus = data.netWorkStatus || 0;
                console.log(this.netWorkStatus);
                if(this.netWorkStatus){
                    this.orderId = data.orderId;
                    this.transactionId = data.transactionId;
                    this.transactionTime = data.transactionTime;
                    this.amount = data.amount;
                    this.payType = data.payType;
                    this.codeUrl = data.codeUrl;
                    this.inTime = data.inTime;
                    this.parkTime = data.parkTime;
                    this.leftMinutes= parseInt(data.leftMills)/1000;

                    initData = {
                            action: 'onIframeReady',
                            displayStyle: 'SHOW_CUSTOM_PAGE',
                            height: "960"
                    };
                    console.log('initData',initData)
                }
            }
            console.log('同步还是异步',initData)
            let initPostData = JSON.stringify(initData);
            parent.postMessage(initPostData, 'https://payapp.weixin.qq.com');
        },
        mounted() {
            this.sub_mch_id = this.getQueryString("sub_mch_id");
            this.out_trade_no = this.getQueryString("out_trade_no");
            this.check_code = this.getQueryString("check_code");
            let parkId = this.getParkId(this.out_trade_no)
            axios.get(`/v1/api/Ad/getAnBoConfig?adPosId=5&parkId=${parkId||1}`).then(res=>{
                let el = document.getElementById('abBox')
                el.innerHTML = `<anboadv anbo-show="advShow" anbo-clicks="advClicks"></anboadv>`
                let data = res.data.data
                __anbo_adv_sdk__.init({
                    appid: data.appid,
                    adPosId: 4,
                    parkId: data.parkId
                })
            });
            axios.get(`/v1/api/Ad/getRotationAd?parkId=${parkId||1}&adPosId=5&subMchId=${this.sub_mch_id}&outTradeNo=${this.out_trade_no}&checkCode=${this.check_code}`).then(res=>{
                // this.imgUrl = res.data.data["ad"][0].pic;
                // this.clickUrl = res.data.data["ad"][0].clickUrl;
                this.ad = res.data.data["ad"];
            });
            axios.get(`/v1/api/fapiao/invoice_after_pay?sub_mch_id=${this.sub_mch_id}&out_trade_no=${this.out_trade_no}&check_code=${this.check_code}`).then(res=>{
                //this.parkId = res.data.data.parkId;
                this.orderId = res.data.data.orderId;
                this.parkId = res.data.data.parkId;
                this.transactionId = res.data.data.transactionId;
                this.licencePlate = res.data.data.licencePlate;
                this.amount = res.data.data.amount;
                this.parkName = res.data.data.parkName;
                this.enableInvoice = res.data.data.enableInvoice;
            });
        },
        components:{
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem,
            disconnectionHomeVue:disconnection_homeVue
        }
    }
</script>

<style scoped>
    .success{
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    }
    .success div{
        text-align: center;
    }
    .success .success-img{
        margin-bottom: 5px;
    }
    .success .text{
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
    }
    .success .button{
        width: 35%;
        margin: 0 auto 20px;
    }
    .button button{
        background-color: #07c160;
        color: white;
        border: none;
        font-size: 20px;
        padding: 10px 15px;
        border-radius: 3px;
        outline: 0;
    }
    .img-box{
        padding: 0 10px;
    }
    .success img{
        width: 15%;
    }
    .img-box a{
        width: 100%;
    }
    .img-box img{
        width: 100%;
    }
</style>
